import React from 'react';
import { Dropdown, Layout } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    LogoutOutlined,
    LockOutlined
} from '@ant-design/icons';
import { Link } from "react-router-dom";
import './GeneralHeader.less';
import { useAuth } from '../../../hooks/auth/AuthContext';
import LanguageSwitcher from '../../widgets/language-switcher/LanguageSwitcher';
import { useIntl } from 'react-intl';
const { Header } = Layout;
interface Props {
    collapsed: boolean;
    toggleSideNav?: () => void;
    logout?: () => void;
}

const GeneralHeader = ({ collapsed, toggleSideNav, logout }: Props) => {
    const { authState } = useAuth();
    const intl = useIntl();
    const name = authState.userProfile && authState.userProfile?.name ?
        authState.userProfile?.name : '';


    const items: any = [
        { key: 'm-dd-1', icon: <LockOutlined />, label: <><Link to="/settings/change-password">{intl.formatMessage({ id: "CHANGE_PASSWORD" })}</Link></>, }, // remember to pass the key prop
        { key: 'm-dd-2', icon: <LogoutOutlined />, label: <>{intl.formatMessage({ id: "LOGOUT" })}</>, onClick: logout },
    ];

    return (
        <Header className="site-layout-header" style={{ padding: 0 }}>
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: toggleSideNav,
            })}
            <div>
                {/* <LanguageSwitcher /> */}
                <Dropdown menu={{ items }} className="profile-menu" trigger={['hover', 'click']}>
                    <a className="ant-dropdown-link" href="/#" >  <UserOutlined /><span className="profile-menu-title">{name}</span> </a>
                </Dropdown>
            </div>
        </Header>
    )
}

export default GeneralHeader;