import { gql } from "@apollo/client";


export const GET_SIMPLY_MERCHANTS = gql`
    query SimplyMerchants($isMerchant: Boolean!){
        simplyMerchants(isMerchant:$isMerchant){
            id,
            name
        }
    }
`;

export const GET_MERCHANTS = gql`
    query Merchants($bFilterInput: MerchantFilterInput!){
        merchants(bFilterInput: $bFilterInput){
            count(bFilterInput: $bFilterInput),
            merchants{
                id,
                name,
                ip,
                apiKey,
                secretKey,
                type,
                createdAt,
                createdBySystemUser{
                    name
                },
                updatedAt,
                updatedBySystemUser{
                    name
                },
                code
            }
        }
    }
`;

export const GET_MERCHANT = gql`
    query Merchant($id: ID!){
        merchant(id: $id){
            id,
            name,
            ip,
            apiKey,
            secretKey,
            type,
            createdAt,
            createdBySystemUser{
                name
            },
            updatedAt,
            updatedBySystemUser{
                name
            },
            code,
            backofficeIP
        }
    }
`;

export const GET_SELECTION_MERCHANTS = gql`
    query SelectionMerchants{
        selectionMerchants{
            id,
            name
        }
    }
`;

export const GET_MERCHANT_WALLETS = gql`
    query MerchantWallets($merchantId:String!){
        merchantWallets(merchantId:$merchantId){
            id,
            merchantId,
            currencyId,
            balance
        }
    }
`;

export const GET_MERCHANT_WALLET_BY_CURRENCY = gql`
    query MerchantWalletByCurrency($merchantId:String!, $currencyId:String!){
        merchantWalletByCurrency(merchantId:$merchantId, currencyId:$currencyId){
            balance,
            settlementRate,
            settlementFee
        }
    }
`;

export const CREATE_MERCHANT = gql`
    mutation CreateMerchant($bInput:MerchantInput!){
        createMerchant(bInput:$bInput){
            status,
            userName, 
            password, 
            merchantCode, 
            secretKey
        }
    }
`;

export const UPDATE_MERCHANT = gql`
    mutation UpdateMerchant($bInput:MerchantInput!){
        updateMerchant(bInput:$bInput){
            status,
            id
        }
    }
`;