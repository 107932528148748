import React from "react";
import NoMatch from "./NoMatch";

export interface RouteInfo {
    path: string,
    component: any,
    routes?: RouteInfo[],
    lazy?: boolean,
    protected?: boolean
}

const ReactLazyPreload = (importStatement: any) => {
    const Component: any = React.lazy(importStatement);
    Component.preload = importStatement;
    return Component;
};

//preload pages
const SideNavigationForm = ReactLazyPreload(() => import('./pages/side-navigation/SideNavigationForm'));
SideNavigationForm.preload();
const SideNavigation = ReactLazyPreload(() => import('./pages/side-navigation/SideNavigation'));
SideNavigation.preload();
const SystemUserForm = ReactLazyPreload(() => import('./pages/system-user/SystemUserForm'));
SystemUserForm.preload();
const SystemUser = ReactLazyPreload(() => import('./pages/system-user/SystemUser'));
SystemUser.preload();
const SystemConfiguration = ReactLazyPreload(() => import('./pages/system-configuration/SystemConfiguration'));
SystemConfiguration.preload();
const ChangePasswords = ReactLazyPreload(() => import('./pages/settings/change-password/ChangePassword'));
ChangePasswords.preload();
const MyAccount = ReactLazyPreload(() => import('./pages/settings/my-account/MyAccount'));
MyAccount.preload();
const Role = ReactLazyPreload(() => import('./pages/role/Role'));
Role.preload();
const RoleForm = ReactLazyPreload(() => import('./pages/role/RoleForm'));
RoleForm.preload();
const Bank = ReactLazyPreload(() => import('./pages/banks/Bank'));
Bank.preload();
const BankForm = ReactLazyPreload(() => import('./pages/banks/BankForm'));
BankForm.preload();

const Merchant = ReactLazyPreload(() => import('./pages/merchants/Merchant'));
Merchant.preload();

const Agent = ReactLazyPreload(() => import('./pages/agents/Agent'));
Agent.preload();
const AddAgent = ReactLazyPreload(() => import('./pages/agents/AgentForm'));
AddAgent.preload();
const AgentForm = ReactLazyPreload(() => import('./pages/agents/AgentForm'));
AgentForm.preload();
const MerchantForm = ReactLazyPreload(() => import('./pages/merchants/MerchantForm'));
MerchantForm.preload();
const MerchantDetail = ReactLazyPreload(() => import('./pages/merchants/MerchantDetail'));
MerchantDetail.preload();
const BankAccount = ReactLazyPreload(() => import('./pages/bank-accounts/BankAccounts'));
BankAccount.preload();
const BankAccountForm = ReactLazyPreload(() => import('./pages/bank-accounts/BankAccountsForm'));
BankAccountForm.preload();
const BankAccountMerchantForm = ReactLazyPreload(() => import('./pages/bank-accounts/BankAccountsMerchantForm'));
BankAccountMerchantForm.preload();
const BankAccountAgentForm = ReactLazyPreload(() => import('./pages/bank-accounts/BankAccountsAgentForm'));
BankAccountAgentForm.preload();
const SystemUserLog = ReactLazyPreload(() => import('./pages/logs/system-user/SystemUserLog'));
SystemUserLog.preload();
const TransactionLog = ReactLazyPreload(() => import('./pages/logs/transaction/TransactionLog'));
TransactionLog.preload();
const MerchantLog = ReactLazyPreload(() => import('./pages/logs/merchant/MerchantLog'));
MerchantLog.preload();

const ExportLog = ReactLazyPreload(() => import('./pages/logs/export/ExportLog'));
ExportLog.preload();
const BankAccountLog = ReactLazyPreload(() => import('./pages/logs/bank-account/BankAccountLog'));
BankAccountLog.preload();
const Settlement = ReactLazyPreload(() => import('./pages/settlements/Settlement'));
Settlement.preload();
const SettlementApproval = ReactLazyPreload(() => import('./pages/settlements/SettlementApproval'));
SettlementApproval.preload();
const SettlementForm = ReactLazyPreload(() => import('./pages/settlements/SettlementForm'));
SettlementForm.preload();
const AgentLog = ReactLazyPreload(() => import('./pages/logs/agent/AgentLog'));
AgentLog.preload();
const Adjustment = ReactLazyPreload(() => import('./pages/adjustments/Adjustment'));
Adjustment.preload();
const AdjustmentForm = ReactLazyPreload(() => import('./pages/adjustments/AdjustmentForm'));
AdjustmentForm.preload();
const BalanceHistory = ReactLazyPreload(() => import('./pages/balance-history/BalanceHistory'));
BalanceHistory.preload();
const TransactionSupport = ReactLazyPreload(() => import('./pages/transaction-support/TransactionSupport'));
TransactionSupport.preload();
const DailyStatement = ReactLazyPreload(() => import('./pages/report/DailyStatement'));
DailyStatement.preload();
const Dashboard = ReactLazyPreload(() => import('./pages/dashboard/Dashboard'));
Dashboard.preload();

const Payout = ReactLazyPreload(() => import('./pages/payouts/Payout'));
Payout.preload();
const PayoutGroup = ReactLazyPreload(() => import('./pages/payout-groups/PayoutGroup'));
PayoutGroup.preload();
const PayoutGroupForm = ReactLazyPreload(() => import('./pages/payout-groups/PayoutGroupForm'));
PayoutGroupForm.preload();
const PayoutGroupMerchantForm = ReactLazyPreload(() => import('./pages/payout-groups/PayoutGroupsMerchantForm'));
PayoutGroupMerchantForm.preload();
const PayoutDetail = ReactLazyPreload(() => import('./pages/payouts/PayoutDetail'));
PayoutDetail.preload();

const Payin = ReactLazyPreload(() => import('./pages/payins/Payin'));
Payin.preload();
const PayinGroup = ReactLazyPreload(() => import('./pages/payin-groups/PayinGroup'));
PayinGroup.preload();
const PayinGroupForm = ReactLazyPreload(() => import('./pages/payin-groups/PayinGroupForm'));
PayinGroupForm.preload();
const PayinGroupMerchantForm = ReactLazyPreload(() => import('./pages/payin-groups/PayinGroupsMerchantForm'));
PayinGroupMerchantForm.preload();
const PayinForm = ReactLazyPreload(() => import('./pages/payins/PayinForm'));
PayinForm.preload();

const BankTransactions = ReactLazyPreload(() => import('./pages/bank-transactions/BankTransactions'));
BankTransactions.preload();
const BankBalanceHistories = ReactLazyPreload(() => import('./pages/bank-balance-histories/BankBalanceHistories'));
BankBalanceHistories.preload();

const TransferTypes = ReactLazyPreload(() => import('./pages/transfer-types/TransferType'));
TransferTypes.preload();
const TransferTypeForm = ReactLazyPreload(() => import('./pages/transfer-types/TransferTypeForm'));
TransferTypeForm.preload();

const Gateways = ReactLazyPreload(() => import('./pages/gateway/Gateway'));
Gateways.preload();
const GatewayDetail = ReactLazyPreload(() => import('./pages/gateway/GatewayDetail'));
GatewayDetail.preload();

const GatewayAccounts = ReactLazyPreload(() => import('./pages/gateway-accounts/GatewayAccount'));
GatewayAccounts.preload();
const GatewayAccountForm = ReactLazyPreload(() => import('./pages/gateway-accounts/GatewayAccountForm'));
GatewayAccountForm.preload();
const GatewayAccountDetail = ReactLazyPreload(() => import('./pages/gateway-accounts/GatewayAccountDetail'));
GatewayAccountDetail.preload();

const Group = ReactLazyPreload(() => import('./pages/groups/Group'));
Group.preload();
const GroupForm = ReactLazyPreload(() => import('./pages/groups/GroupForm'));
GroupForm.preload();
const GroupDetail = ReactLazyPreload(() => import('./pages/groups/GroupDetail'));
GroupDetail.preload();

const MerchantUser = ReactLazyPreload(() => import('./pages/merchant-users/MerchantUser'));
MerchantUser.preload();

const KKPAYBankAccount = ReactLazyPreload(() => import('./pages/kkpay-bank-accounts/KKPAYBankAccount'));
KKPAYBankAccount.preload();
const KKPAYBankAccountForm = ReactLazyPreload(() => import('./pages/kkpay-bank-accounts/KKPAYBankAccountForm'));
KKPAYBankAccountForm.preload();
const KKPAYBankAccountDetail = ReactLazyPreload(() => import('./pages/kkpay-bank-accounts/KKPAYBankAccountDetail'));
KKPAYBankAccountDetail.preload();

const KKPAYProxies = ReactLazyPreload(() => import('./pages/kkpay-proxies/KKPAYProxies'));
KKPAYProxies.preload();
const KKPAYProxiesForm = ReactLazyPreload(() => import('./pages/kkpay-proxies/KKPAYProxiesForm'));
KKPAYProxiesForm.preload();
const KKPAYProxiesDetail = ReactLazyPreload(() => import('./pages/kkpay-proxies/KKPAYProxiesDetail'));
KKPAYProxiesDetail.preload();

const KKPAYMerchantSetting = ReactLazyPreload(() => import('./pages/kkpay-merchant-settings/KKPAYMerchantSetting'));
KKPAYMerchantSetting.preload();

const KKPAYPayoutTransaction = ReactLazyPreload(() => import('./pages/kkpay-payout-transactions/KKPAYPayoutTransaction'));
KKPAYPayoutTransaction.preload();

export const routes: RouteInfo[] = [
    {
        path: "/login",
        component: React.lazy(() => import('./pages/login/Login'))
    }, {
        path: "/transactions/settlement/add-settlement",
        component: SettlementForm,
        protected: true
    }, {
        path: "/transactions/settlement",
        component: Settlement,
        protected: true
    }, {
        path: "/transactions/pay-out",
        component: Payout,
        protected: true
    }, {
        path: "/transactions/pay-out/edit-pay-out/:id",
        component: PayoutDetail,
        protected: true
    }, {
        path: "/transactions/pay-in-promptpay",
        component: Payin,
        protected: true
    }, {
        path: "/transactions/pay-in/edit-pay-in/:id",
        component: PayinForm,
        protected: true
    }, {
        path: "/transactions/pay-in",
        component: Payin,
        protected: true
    }, {
        path: "/logs/bank-accounts",
        component: BankAccountLog,
        protected: true
    }, {
        path: "/logs/export",
        component: ExportLog,
        protected: true
    }, {
        path: "/logs/merchants",
        component: MerchantLog,
        protected: true
    }, {
        path: "/logs/system-users",
        component: SystemUserLog,
        protected: true
    }, {
        path: "/logs/transactions",
        component: TransactionLog,
        protected: true
    }, {
        path: "/logs/agents",
        component: AgentLog,
        protected: true
    }, {
        path: "/reports/daily-statement",
        component: DailyStatement,
        protected: true
    }, {
        path: "/reports/bank-transactions",
        component: BankTransactions,
        protected: true
    }, {
        path: "/reports/bank-balance-history",
        component: BankBalanceHistories,
        protected: true
    }, {
        path: "/transactions/settlement/settlement-approval/:id",
        component: SettlementApproval,
        protected: true
    }, {
        path: "/admin/side-navigation/add-side-navigation",
        component: SideNavigationForm,
        protected: true
    }, {
        path: "/admin/side-navigation/edit-side-navigation/:id",
        component: SideNavigationForm,
        protected: true
    }, {
        path: "/admin/side-navigation",
        component: SideNavigation,
        protected: true
    }, {
        path: "/admin/system-users/edit-system-user/:id",
        component: SystemUserForm,
        protected: true
    }, {
        path: "/admin/system-users/add-system-user",
        component: SystemUserForm,
        protected: true
    }, {
        path: "/admin/system-users",
        component: SystemUser,
        protected: true
    }, {
        path: "/merchants/merchant-user",
        component: MerchantUser,
        protected: true
    }, {
        path: "/admin/configuration",
        component: SystemConfiguration,
        protected: true
    }, {
        path: "/admin/banks/edit-bank/:id",
        component: BankForm,
        protected: true
    }, {
        path: "/admin/banks/add-bank",
        component: BankForm,
        protected: true
    }, {
        path: "/admin/banks",
        component: Bank,
        protected: true
    }, {
        path: "/adjustment/add-adjustment",
        component: AdjustmentForm,
        protected: true
    }, {
        path: "/adjustment",
        component: Adjustment,
        protected: true
    }, {
        path: "/admin/transaction-support",
        component: TransactionSupport,
        protected: true
    }, {
        path: "/admin/transfer-type/edit-transfer-type/:id",
        component: TransferTypeForm,
        protected: true
    }, {
        path: "/admin/transfer-type/add-transfer-type",
        component: TransferTypeForm,
        protected: true
    }, {
        path: "/admin/transfer-type",
        component: TransferTypes,
        protected: true
    }, {
        path: "/settings/change-password",
        component: ChangePasswords,
        protected: true
    }, {
        path: "/settings/my-account",
        component: MyAccount,
        protected: true
    }, {
        path: "/admin/role/edit-role/:id",
        component: RoleForm,
        protected: true
    }, {
        path: "/admin/role/add-role",
        component: RoleForm,
        protected: true
    }, {
        path: "/admin/roles",
        component: Role,
        protected: true
    }, {
        path: "/merchants/merchants/edit-merchant/:id",
        component: MerchantDetail,
        protected: true
    }, {
        path: "/merchants/merchants/add-merchant",
        component: MerchantForm,
        protected: true
    }, {
        path: "/merchants/merchants",
        component: Merchant,
        protected: true
    }, {
        path: "/merchants/balance-history",
        component: BalanceHistory,
        protected: true
    }, {
        path: "/merchants/agents",
        component: Agent,
        protected: true
    }, {
        path: "/merchants/agents/edit-agent/:id",
        component: AgentForm,
        protected: true
    }, {
        path: "/merchants/agents/add-agent/",
        component: AddAgent,
        protected: true
    }, {
        path: "/merchants/bank-accounts/link-merchants/:id",
        component: BankAccountMerchantForm,
        protected: true
    }, {
        path: "/merchants/bank-accounts/link-agents/:id",
        component: BankAccountAgentForm,
        protected: true
    }, {
        path: "/bank-accounts/bank-setting/edit-bank-accounts/:id",
        component: BankAccountForm,
        protected: true
    }, {
        path: "/bank-accounts/bank-setting/add-bank-accounts",
        component: BankAccountForm,
        protected: true
    }, {
        path: "/bank-accounts/bank-setting",
        component: BankAccount,
        protected: true
    }, {
        path: "/dashboard",
        component: Dashboard,
        protected: true
    }, {
        path: "/bank-accounts/payout-group/link-merchants/:id",
        component: PayoutGroupMerchantForm,
        protected: true
    }, {
        path: "/bank-accounts/payout-group/edit-payout-group/:id",
        component: PayoutGroupForm,
        protected: true
    }, {
        path: "/bank-accounts/payout-group/add-payout-group",
        component: PayoutGroupForm,
        protected: true
    }, {
        path: "/bank-accounts/payout-group",
        component: PayoutGroup,
        protected: true
    }, {
        path: "/bank-accounts/payin-group/link-merchants/:id",
        component: PayinGroupMerchantForm,
        protected: true
    }, {
        path: "/bank-accounts/payin-group/edit-payin-group/:id",
        component: PayinGroupForm,
        protected: true
    }, {
        path: "/bank-accounts/payin-group/add-payin-group",
        component: PayinGroupForm,
        protected: true
    }, {
        path: "/bank-accounts/payin-group",
        component: PayinGroup,
        protected: true
    }, {
        path: "/providers/gateways/edit/:id",
        component: GatewayDetail,
        protected: true
    }, {
        path: "/providers/gateways",
        component: Gateways,
        protected: true
    }, {
        path: "/providers/accounts/edit-accounts/:id",
        component: GatewayAccountDetail,
        protected: true
    }, {
        path: "/providers/accounts/add-accounts",
        component: GatewayAccountForm,
        protected: true
    }, {
        path: "/providers/accounts",
        component: GatewayAccounts,
        protected: true
    }, {
        path: "/groups/edit-groups/:id",
        component: GroupDetail,
        protected: true
    }, {
        path: "/groups/add-groups",
        component: GroupForm,
        protected: true
    }, {
        path: "/groups",
        component: Group,
        protected: true
    }, {
        path: "/kkpay/accounts/add-account",
        component: KKPAYBankAccountForm,
        protected: true
    }, {
        path: "/kkpay/accounts/edit-account/:id",
        component: KKPAYBankAccountDetail,
        protected: true
    }, {
        path: "/kkpay/accounts",
        component: KKPAYBankAccount,
        protected: true
    }, {
        path: "/kkpay/proxies/add-proxy",
        component: KKPAYProxiesForm,
        protected: true
    }, {
        path: "/kkpay/proxies/edit-proxy/:id",
        component: KKPAYProxiesDetail,
        protected: true
    }, {
        path: "/kkpay/proxies",
        component: KKPAYProxies,
        protected: true
    }, {
        path: "/kkpay/merchants",
        component: KKPAYMerchantSetting,
        protected: true
    }, {
        path: "/kkpay/payouts",
        component: KKPAYPayoutTransaction,
        protected: true
    }, {
        path: "*",
        component: NoMatch
    }
];